import { createEffect, type Accessor } from "solid-js";
import type { Logger } from "@repo/logger";
import {
  createAuthStore,
  getAuthIsAuthenticated,
  getAuthIsError,
  getAuthIsGuest,
  getAuthIsLoading,
} from "./auth.store";
import type { AuthServiceMethodsImplementation, AuthStoreWorkingContext } from "./auth.types";
import type { stid } from "@repo/client";
import { stAnalytics } from "@repo/analytics";
import { getEmailTypeFromDomain } from "@core/lib/isEmailPersonalOrBusiness";

const emptyWorkingContext = (): AuthStoreWorkingContext => ({
  organizationId: "" as stid.OrganizationStringID,
  tenantId: "" as stid.OrganizationStringID,
  collectionTree: [],
  operationId: "" as stid.OperationStringID,
});

export type AuthService = ReturnType<typeof useAuthService>;

export const useAuthService = (deps: {
  // Dependency inject a different auth implementation for different environments (browser and extension so far)
  implementation: AuthServiceMethodsImplementation;
  logger: Logger;
  deviceId: Accessor<string>;
  locale: Accessor<string>;
  userAgent: Accessor<string>;
}) => {
  const [store, setStore] = createAuthStore();

  /**
   * Is user authenticated with a verified email (magic link or google)
   */
  const isVerified = () => getAuthIsAuthenticated(store);
  /**
   * Is user authenticated with an anonymous account
   */
  const isGuest = () => getAuthIsGuest(store);
  /**
   * Is the auth service detecting the user's identity
   */
  const isLoading = () => getAuthIsLoading(store);
  /**
   * Is the auth service in an error state
   */
  const isError = () => getAuthIsError(store);
  /**
   * Is the auth service not ready to use (user has not been detected as either verified or guest)
   */
  const isConnecting = () => isLoading() || isError();
  /**
   * Is the auth service ready to use (user has been detected as either verified or guest)
   */
  const isReady = () => !isConnecting();

  /**
   * The user's working context with a non-undefined default value (for compatibility with older code that expects a non-undefined value)
   */
  const workingContext = () => store.user?.workingContext ?? emptyWorkingContext();

  const user = () => store.user;
  const error = () => store.error;
  const token = () => store.token;

  const methods = deps.implementation({
    logger: deps.logger,
    store,
    setStore,
    // TODO: get these from the browser
    deviceId: deps.deviceId,
    locale: deps.locale,
    userAgent: deps.userAgent,
  });

  createEffect(() => {
    if (isVerified()) {
      const i = user();
      if (!i) return;

      stAnalytics.identify(i.userId, {
        name: i.displayName,
        email: i.email,
        emailType: getEmailTypeFromDomain(i.email),
        potentialScore: i.potentialScore,
      });
    }
  });

  methods.setupListeners();

  return {
    isVerified,
    isGuest,
    isLoading,
    isError,
    isConnecting,
    isReady,
    user,
    workingContext,
    error,
    token,
    methods,
    /**
     * @deprecated try not to use this, use the getter methods provided by the service
     */
    store,
  };
};
