/*
 * Consistent reference to element used for attaching & dispatching.
 */
const element = () => globalThis.document.documentElement;

/*
 * Local function to ensure dispatching of custom events is done on the body element.
 */
const dispatch = (event: CustomEvent) => element().dispatchEvent(event);

/*
 * Reference for naming of custom events.
 */
const GLOBAL_EVENT_NAMES = {
  uploadCompleted: "asset_upload_complete",
  collectionPermissionChange: "collection_permission_change",
  collectionThreadsUpdated: "collection_threads_updated",
  scrollCollectionIntoView: "scroll_collection_into_view",
  saveContentAsAsset: "save_content_as_asset",
  promptSubmitted: "prompt_submitted",
  collectionTreeUpdated: "collection_tree_updated",
} as const;

interface UploadCompletedEvent {
  collectionId?: string;
}

interface ScrollCollectionIntoView {
  collectionId: string;
}

interface SaveContentAsAsset {
  source: string;
  name: string;
  size: number;
  file: File;
  collectionId: string;
  organizationId: string;
  tenantId: string;
  forThread: boolean;
}

/*
 * Store for attaching & removing custom DOM event listeners.
 */
export const CustomEvents = {
  uploadCompleted: {
    add: (listener: (event: CustomEvent<UploadCompletedEvent>) => void) =>
      element().addEventListener(GLOBAL_EVENT_NAMES.uploadCompleted, listener as EventListener),
    remove: (listener: (event: CustomEvent<UploadCompletedEvent>) => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.uploadCompleted, listener as EventListener),
    dispatch: (detail?: UploadCompletedEvent) => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.uploadCompleted, {
        bubbles: false,
        cancelable: false,
        detail,
      });
      dispatch(event);
    },
  },
  collectionPermissionChange: {
    add: (listener: () => void) => element().addEventListener(GLOBAL_EVENT_NAMES.collectionPermissionChange, listener),
    remove: (listener: () => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.collectionPermissionChange, listener),
    dispatch: () => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.collectionPermissionChange, {
        bubbles: false,
        cancelable: false,
      });
      dispatch(event);
    },
  },
  collectionThreadsUpdated: {
    add: (listener: () => void) => element().addEventListener(GLOBAL_EVENT_NAMES.collectionThreadsUpdated, listener),
    remove: (listener: () => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.collectionThreadsUpdated, listener),
    dispatch: () => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.collectionThreadsUpdated, {
        bubbles: false,
        cancelable: false,
      });
      dispatch(event);
    },
  },
  scrollCollectionIntoView: {
    add: (listener: (event: CustomEvent<ScrollCollectionIntoView>) => void) =>
      element().addEventListener(GLOBAL_EVENT_NAMES.scrollCollectionIntoView, listener as EventListener),
    remove: (listener: (event: CustomEvent<ScrollCollectionIntoView>) => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.scrollCollectionIntoView, listener as EventListener),
    dispatch: (detail: ScrollCollectionIntoView) => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.scrollCollectionIntoView, {
        bubbles: false,
        cancelable: false,
        detail,
      });
      dispatch(event);
    },
  },

  saveContentAsAsset: {
    add: (listener: (event: CustomEvent<SaveContentAsAsset>) => void) =>
      element().addEventListener(GLOBAL_EVENT_NAMES.saveContentAsAsset, listener as EventListener),
    remove: (listener: (event: CustomEvent<SaveContentAsAsset>) => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.saveContentAsAsset, listener as EventListener),
    dispatch: (detail: SaveContentAsAsset) => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.saveContentAsAsset, {
        bubbles: false,
        cancelable: false,
        detail,
      });
      dispatch(event);
    },
  },

  promptSubmitted: {
    add: (listener: (event: CustomEvent) => void) =>
      element().addEventListener(GLOBAL_EVENT_NAMES.promptSubmitted, listener as EventListener),
    remove: (listener: (event: CustomEvent) => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.promptSubmitted, listener as EventListener),
    dispatch: () => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.promptSubmitted, {
        bubbles: false,
        cancelable: false,
      });
      dispatch(event);
    },
  },
  collectionTreeUpdated: {
    add: (listener: () => void) => element().addEventListener(GLOBAL_EVENT_NAMES.collectionTreeUpdated, listener),
    remove: (listener: () => void) => element().removeEventListener(GLOBAL_EVENT_NAMES.collectionTreeUpdated, listener),
    dispatch: () => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.collectionTreeUpdated, { bubbles: false, cancelable: false });
      dispatch(event);
    },
  },
} as const;
