import type { AuthStore, AuthStoreUser, SetAuthStoreFunc } from "./auth.types";
import { createStore } from "solid-js/store";

const initialStore = (): AuthStore => ({
  state: "loading",
  token: undefined,
  user: undefined,
  error: undefined,
});

export const createAuthStore = () => createStore<AuthStore>(initialStore());

// Getters
export const getAuthIsAuthenticated = (store: AuthStore) => store.state === "authenticated";
export const getAuthIsGuest = (store: AuthStore) => store.state === "guest";
export const getAuthIsLoading = (store: AuthStore) => store.state === "loading";
export const getAuthIsError = (store: AuthStore) => store.state === "error";

// Setters
export const setAuthUser = (setStore: SetAuthStoreFunc, user: AuthStoreUser) =>
  setStore({
    error: undefined,
    state: "authenticated",
    user,
  });
export const setAuthGuest = (setStore: SetAuthStoreFunc, user: AuthStoreUser) =>
  setStore({
    error: undefined,
    state: "guest",
    user,
  });
export const setAuthError = (setStore: SetAuthStoreFunc, error: string) =>
  setStore({
    error,
    state: "error",
    user: undefined,
    token: undefined,
  });
export const setAuthLoading = (setStore: SetAuthStoreFunc) => setStore(initialStore());
