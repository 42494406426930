import { identity } from "@core/domains/identity/Identity.i18n.en";
import { ctxHeader } from "../ctx/CtxHeader.i18n.en";

export const dictionary = {
  ctxHeader,
  identity,
};

/**
 * Dictionary represents the authoritative source of translations for the application.
 */
export type Dictionary = typeof dictionary;
