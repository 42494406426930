import { assign, setup } from "xstate";
import type { LocaleEvents } from "@core/domains/i18n/machine/localeEvents";
import { type LocaleKey, type LocaleStore, Locales } from "@core/domains/i18n/types/types";

const initialContext = (): LocaleStore => ({
  locale: "en",
  error: "",
  library: {},
});

export enum LocaleStates {
  Start = "start",
  Detecting = "detecting",
  Idle = "idle",
}

export const localeMachine = setup({
  types: {
    context: {} as LocaleStore,
    events: {} as LocaleEvents,
  },
}).createMachine({
  id: "localeMachine",
  initial: "detecting",
  context: initialContext(),
  states: {
    detecting: {
      on: {
        "locale.switch": {
          guard: ({ event }) => {
            return event.locale && !!Locales[event.locale as keyof typeof Locales];
          },
          actions: assign({
            locale: ({ event }) => event.locale,
            error: undefined,
          }),
          target: "idle",
        },
      },
    },
    idle: {
      on: {
        "locale.switch": {
          guard: ({ event }) => event.locale && !!Locales[event.locale as keyof typeof Locales],
          actions: assign({
            locale: ({ event }) => event.locale,
            error: undefined,
          }),
          target: "idle",
        },
      },
    },
  },
});

const detectLocale = (): LocaleKey => {
  return "en";
};
