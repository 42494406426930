import type { AnalyticsPlugin } from "analytics";
import type { Properties, PostHog } from "posthog-js";
// Importing posthog like this so that the chrome extension passes review. Having external dependencies
// that are loaded dynamically is a no-no.
// See these 2 issues for more info:
// https://github.com/PostHog/posthog-js/issues/1394
// https://github.com/PostHog/posthog-js/issues/1464
import posthog from "posthog-js?client";
// Comment the top import and uncomment this one when building for the chrome extension
// I'll find an automatic way to do this soon
//@ts-ignore
// import posthog from "posthog-js/dist/module.no-external?client";

// Coming from vite config in app.config.ts
declare const POSTHOG_API_KEY: string;
declare const APP_DOMAIN: string;

export const PostHogHost = `https://${APP_DOMAIN}/ingest`;

let isPostHogLoaded = false;

export const posthogAnalyticsPlugin = (): AnalyticsPlugin => {
  const plugin: AnalyticsPlugin = {
    name: "posthog",
    initialize: () => {
      if (import.meta.env.SSR) return;

      posthog.init(POSTHOG_API_KEY, {
        api_host: PostHogHost,
        loaded: () => {
          isPostHogLoaded = true;
        },
        bootstrap: {
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          featureFlags: (typeof window === "undefined" ? {} : (window as any)).__ST_EXPERIMENTS_BOOTSTRAP__,
        },
      });
    },
    identify: ({
      payload,
    }: {
      payload: {
        userId: string;
        traits: Properties;
      };
    }) => {
      posthog.identify(payload.userId, payload.traits);
    },
    track: (event: {
      payload: { event: string; properties: Properties };
    }) => {
      posthog.capture(event.payload.event, event.payload.properties);
    },
    page: ({ payload }: { payload: Properties }): void => {
      posthog.capture("$pageview", payload.properties);
    },
    loaded: () => {
      // Don't send analytics events on the server
      if (import.meta.env.SSR) return false;

      return isPostHogLoaded;
    },
    methods: {
      reset() {
        posthog.reset();
      },
      setPersonProperties(properties: Properties) {
        posthog.setPersonProperties(properties);
      },
      reloadFeatureFlags() {
        return (posthog as PostHog).reloadFeatureFlags();
      },
      onFlagsLoaded(callback: (flags: string[], variants: Record<string, string | boolean>) => void) {
        return (posthog as PostHog).onFeatureFlags(callback);
      },
    },
  };

  return plugin;
};
