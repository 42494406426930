import type { Component } from "solid-js";
import type { ExportImport } from "../types";
import { encode } from "../service";

export type SerializeServerProps = {
  values: ExportImport;
};

export const SerializeServerValues: Component<SerializeServerProps> = (props) => {
  return <script id="server_serialized_values">{`window.__ST_EXPORT = ${encode(props.values)}`}</script>;
};
