import { stAnalytics } from "@repo/analytics";
import { type Experiments, fetchExperimentsServer, mapExperimentsToPosthogFeatureFlags } from "@repo/experiments";
import { Named, type Logger } from "@repo/logger";
import { useSearchParams } from "@solidjs/router";
import { createEffect, createResource, onMount } from "solid-js";
import { produce } from "solid-js/store";

export type FeatureFlagServiceDependencies = {
  logger: Logger;
  getDeviceId: () => string;
  isVerified: () => boolean;
};

export const useExperimentsService = (deps: FeatureFlagServiceDependencies) => {
  const logger = new Named(deps.logger, "featureFlagsService");
  const [params] = useSearchParams();

  const [experiments, { mutate }] = createResource(
    deps.getDeviceId,
    async (id) =>
      await fetchExperimentsServer(
        id,
        params as Record<string, string>, // TODO: Overriding this type since solid added string[] as a potential type for some route params, should find a better way
        logger,
      ),
  );

  onMount(() => {
    stAnalytics.plugins.posthog.onFlagsLoaded((_, variants) => {
      mutate(
        produce((state) => {
          Object.entries(variants).forEach(([key, value]) => {
            if (!state) return;
            const exp = state[key as keyof Experiments];
            if (!exp) {
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              (state[key as keyof Experiments] as any) = { variant: value };
              return;
            }
            exp.variant = value as boolean;
          });
        }),
      );
    });
  });

  createEffect(() => {
    // We need to set the flags on the window so that the posthog client-side initializer
    // Picks up the values when bootstrapping

    const exp = experiments();
    if (!exp) return;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (window as any).__ST_EXPERIMENTS_BOOTSTRAP__ = mapExperimentsToPosthogFeatureFlags(exp);
  });

  createEffect(() => {
    if (!deps.isVerified()) return;
    stAnalytics.plugins.posthog.reloadFeatureFlags();
  });
  return {
    data: experiments,
  };
};

export type ExperimentsService = ReturnType<typeof useExperimentsService>;
