export const getPullRequestId = () => {
  return process.env.VERCEL_GIT_PULL_REQUEST_ID
    ? stripLeadingTrailingQuotes(process.env.VERCEL_GIT_PULL_REQUEST_ID)
    : "";
};
export const getApiTarget = () => {
  return stripLeadingTrailingQuotes(process.env.STORYTELL_API_TARGET);
};
export const getGitHash = () => {
  return process.env.VERCEL_GIT_COMMIT_SHA ? stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_SHA) : "unknown";
};
export const getFirebaseAPIKey = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_API_KEY);
};
export const getFirebaseAuthDomain = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_AUTH_DOMAIN);
};
export const getFirebaseProjectId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_PROJECT_ID);
};
export const getFirebaseStorageBucket = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_STORAGE_BUCKET);
};
export const getFirebaseMessagingSenderId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_MESSAGING_SENDER_ID);
};
export const getFirebaseAppId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_APP_ID);
};
export const getVercelEnv = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_ENV);
};
export const getVercelURL = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_URL);
};
export const getVercelBranch = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_BRANCH_URL);
};
export const getGitCommitRef = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_REF);
};
export const getVercelCommitSHA = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_SHA);
};
const stripLeadingTrailingQuotes = (str?: string) => {
  return !str ? "" : str.replace(/^"|"$/g, "");
};

const storytellDevelopmentDomain = "storytell-development.ai";
const storytellStagingDomain = "storytell-staging.ai";
const storytellProductionDomain = "storytell.ai";

export const getAppDomain = () => {
  const target = process.env.STORYTELL_API_TARGET || "local";

  if (target === "local") return "localhost:3000";
  if (target === "preview") return storytellDevelopmentDomain;
  if (target === "development") return storytellDevelopmentDomain;
  if (target === "staging") return storytellStagingDomain;

  return storytellProductionDomain;
};

const firebaseDevelopmentDomain = "storytell-development-f686.firebaseapp.com";
const firebaseStagingDomain = "storytell-staging-4f12.firebaseapp.com";
const firebaseProductionDomain = "storytell-production-1def.firebaseapp.com";

export const getFirebaseDomain = () => {
  const target = process.env.STORYTELL_API_TARGET || "local";

  if (target === "local") return firebaseDevelopmentDomain;
  if (target === "preview") return firebaseDevelopmentDomain;
  if (target === "development") return firebaseDevelopmentDomain;
  if (target === "staging") return firebaseStagingDomain;

  return firebaseProductionDomain;
};
