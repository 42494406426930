import { Analytics, type AnalyticsInstance } from "analytics";
import type { Properties, PostHog } from "posthog-js";
import type { AnalyticsEventName, AnalyticsEventPayloads } from "./analyticsEvents";
import { posthogAnalyticsPlugin } from "./posthogAnalyticsPlugin";

type StAnalyticsOptions = {
  plugins: {
    all: boolean;
    posthog: boolean;
  };
};

type StAnalytics = Omit<AnalyticsInstance, "track"> & {
  track: <Key extends AnalyticsEventName>(
    eventName: Key,
    payload: AnalyticsEventPayloads[Key],
    options?: StAnalyticsOptions,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    callback?: (...params: any[]) => any,
  ) => Promise<unknown>;
  plugins: {
    posthog: {
      reset: () => void;
      setPersonProperties: (properties: Properties) => void;
      reloadFeatureFlags: () => Promise<void>;
      onFlagsLoaded: (callback: (flags: string[], variants: Record<string, string | boolean>) => void) => void;
    };
  };
};

/**
 * Singleton for sending analytics events
 *
 * Targets:
 *  - posthog
 *
 * Using https://github.com/DavidWells/analytics as an abstraction in case we want multiple targets
 * in the future
 */
export const stAnalytics: StAnalytics = Analytics({
  app: "storytell-webconsole",
  plugins: [posthogAnalyticsPlugin()],
  // Enables debugging in dev mode, it uses redux internally so we can use the Redux Dev Tools extension to
  // see al events being fired and a timeline for all events
  // https://chromewebstore.google.com/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
  debug: import.meta.env.DEV,
}) as StAnalytics;
