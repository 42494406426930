import type { ThreadEventProps } from "@repo/analytics";
import { isMessagePrompt, isMessageTextV1, type ThreadMessage } from "@repo/client";
import type { AuthStoreWorkingContext } from "@core/domains";
import { useWire } from "@core/wire";

export const useThreadEventProperties = () => {
  const wire = useWire();

  const props = () =>
    getThreadEventProperties({
      workingContext: wire.services.auth.workingContext(),
      threadId: wire.services.threads.snapshot.context.threadId,
      threadMessages: wire.services.threads.messages(),
    });

  return { threadEventProps: props };
};

export const getThreadEventProperties = (deps: {
  workingContext: AuthStoreWorkingContext;
  threadId: string | null;
  threadMessages: ThreadMessage[];
}): ThreadEventProps => ({
  ...deps.workingContext,
  threadId: deps.threadId,
  threadPromptCount: deps.threadMessages.filter((m) => isMessagePrompt(m)).length,
  threadResponseCount: deps.threadMessages.filter((m) => isMessageTextV1(m)).length,
  directoryId: "",
  interactionId: "",
});
