import type { IconTypes } from "solid-icons";
import { CollectionKind } from "./collections.types";

import { BsPersonFillLock } from "solid-icons/bs";
import { OcGlobe2 } from "solid-icons/oc";
import { TbKeyframes, TbLock, TbStar, TbUsersGroup } from "solid-icons/tb";
import { useWire } from "@core/wire";
import type { CollectionSnapshot } from "./collections.types";

// BE is source of truth for these Collection ids
export const PersonalCollectionsSharedWithMeStringID = "collection_cu23ddbfulelfqmdgn50";
const PersonalFavoritesSharedWithMeStringID = "collection_cu29ap3fuler0tajfpd0";

export const isCollectionRoot = (collection: CollectionSnapshot) =>
  [CollectionKind.Personal, CollectionKind.Org, CollectionKind.Favorites].includes(
    collection.collectionKind as CollectionKind,
  ) || collection.id === PersonalCollectionsSharedWithMeStringID;

export function getRootCollectionUIOverloads(collection: CollectionSnapshot): { label: string; icon: IconTypes };
export function getRootCollectionUIOverloads(collection: undefined): undefined;
export function getRootCollectionUIOverloads(
  collection?: CollectionSnapshot,
): { label: string; icon: IconTypes } | undefined;
export function getRootCollectionUIOverloads(collection?: CollectionSnapshot) {
  if (!collection || !collection.id) return;
  if (collection.id === PersonalCollectionsSharedWithMeStringID) {
    return { label: "Personal Collections Shared With Me", icon: BsPersonFillLock };
  }
  if (collection.id === PersonalFavoritesSharedWithMeStringID) {
    return { label: "Personal Favorites Shared With Me", icon: TbStar };
  }
  if (collection.collectionKind === CollectionKind.Org) {
    return { label: "Shared Collections", icon: OcGlobe2 };
  }
  if (collection.collectionKind === CollectionKind.Personal) {
    return { label: "Personal Collection", icon: BsPersonFillLock };
  }

  const wire = useWire();
  const root = wire.services.collections.getCollectionRoot(collection.id);
  if (!root) return { label: collection.label, icon: TbKeyframes };
  if (root.collectionKind === CollectionKind.Personal) {
    return { label: collection.label, icon: TbLock };
  }
  if (root.collectionKind === CollectionKind.Org) {
    return { label: collection.label, icon: TbUsersGroup };
  }
  return { label: collection.label, icon: TbKeyframes };
}
