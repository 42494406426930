import { init, isThreadMessage } from "@repo/client";
import { decodeBase64URI } from "@repo/encoding";
import { Client, Server } from "@repo/logger";
import { useActor } from "@xstate/solid";
import { createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import { isServer } from "solid-js/web";
import { useDeviceService } from "@core/domains/device/service/deviceService";
import { useExperimentsService } from "@core/domains/experiments";
import { useFeedbackService } from "@core/domains/feedback/service/feedbackService";
import { dictionary as defaultDictionary } from "@core/domains/i18n/dictionary/en";
import { localeMachine } from "@core/domains/i18n/machine/localeMachine";
import { useLocaleService } from "@core/domains/i18n/service/localeService";
import { useKnowledgeService } from "@core/domains/knowledge/service";
import { useAppLayoutService } from "@core/domains/layouts/appLayout";
import { useLimitingService } from "@core/domains/limiting";
import { useInputHistoryService, useThreadService } from "@core/domains/threads/service";
import { newOnReceiveError, newWebsocketMachine } from "@core/domains/ws/machines";
import { useWebsocketService } from "@core/domains/ws/service/wsService";
import type { Services, Wire } from "./types";
import { createCollectionsService } from "@core/domains/collections/collections.service";
import { useEnvironmentService, useAuthService, type AuthServiceMethodsImplementation } from "@core/domains";

export function newWire(deps: {
  variant: "extension" | "app";
  authImplementation: AuthServiceMethodsImplementation;
}): Wire {
  // TODO: Hydrate the instance ID so we can tell which server/node spawned this instance of the app.
  const logger = isServer ? new Server("_") : new Client("_");
  const [dictionary, setDictionary] = createStore(defaultDictionary);
  const [isDebug, setIsDebug] = createSignal(true);

  const environment = useEnvironmentService({
    isServer: isServer,
    logger,
  });

  init(environment.settings.values.apiTarget, environment.settings.values.pullRequestId);

  if (!isServer) {
    // @ts-ignore
    window.flag = (s: string) => {
      if (s === "d") {
        setIsDebug(!isDebug());
      }
    };
  }

  const deviceService = useDeviceService({
    logger,
    dictionary,
    variant: deps.variant,
  });

  const localeService = useLocaleService({
    logger,
    actor: useActor(localeMachine),
    setDictionary,
  });

  const authService = useAuthService({
    implementation: deps.authImplementation,
    logger,
    deviceId: deviceService.getDeviceId,
    locale: localeService.locale,
    userAgent: () => navigator.userAgent,
  });

  const experimentsService = useExperimentsService({
    logger,
    getDeviceId: deviceService.getDeviceId,
    isVerified: authService.isVerified,
  });

  const limitingService = useLimitingService({
    isGuest: authService.isGuest,
    experimentsData: experimentsService.data,
  });

  const websocketService = useWebsocketService({
    logger,
    isIdentityConnecting: authService.isConnecting,
    getAuthToken: authService.token,
    actor: useActor(
      newWebsocketMachine({
        logger,
        onReceiveMessage: (eventKey, message) => {
          if (!eventKey && Number.parseInt(message?.code ?? "999", 10) >= 300) {
            const data = JSON.parse(message.data ? decodeBase64URI(message.data) : "{}");
            logger.warn(
              "factory.onReceiveMessage Err",
              `received error message: [${message?.code ?? "-1"}] ${message?.message ?? "Unknown error"}: ${
                data?.message ?? "No error message encoded within message data"
              }`,
              { message, data },
            );
            return;
          }

          const domain = eventKey.split(".")[0];
          // logger.info(
          //   "factory.onReceiveMessage",
          //   `routing message for ${domain} domain`,
          //   { eventKey, message },
          // );
          switch (domain) {
            case "curator":
            case "threads": {
              const decodedMessage = JSON.parse(decodeBase64URI(message.data));
              decodedMessage.eventKey = message.eventKey;
              if (isThreadMessage(decodedMessage)) {
                services.threads.send(services.threads.eventFactory.newThreadsReceiveMessageEvent(decodedMessage));
                return;
              }
              break;
            }
            case "error": {
              services.websocket.send(newOnReceiveError(JSON.parse(decodeBase64URI(message.data))));
              return;
            }
            default:
              logger.warn("factory.onReceiveMessage", `unhandled message: no domain found for ${domain} domain`, {
                eventKey,
                message,
              });
          }
        },
      }),
    ),
  });

  const appLayoutService = useAppLayoutService();
  const feedbackService = useFeedbackService();
  const knowledgeService = useKnowledgeService({
    getAuthToken: authService.token,
    isIdentityConnecting: authService.isConnecting,
  });

  const collectionsService = createCollectionsService({
    getAuthToken: authService.token,
    isGuest: authService.isGuest,
    userId: () => authService.user()?.userId,
    isIdentityReady: authService.isReady,
  });
  collectionsService.reactiveCollectionsFromWorkingContext(authService.workingContext);

  const threadsService = useThreadService({
    logger,
    updateCollections: collectionsService.setCollections,
    getAuthToken: authService.token,
    workingContext: authService.workingContext,
    limitingAddAllowedThread: limitingService.guest.addAllowedThread,
    limitingIsThreadAllowed: limitingService.guest.isThreadAllowed,
    limitingIsInteractionAllowed: limitingService.guest.isInteractionAllowed,
    sendWsMessage: websocketService.send,
    getAssetById: knowledgeService.getAssetById,
    updateAsset: knowledgeService.updateAsset,
    wsMessageIn: () => websocketService.snapshot.context.messageIn,
    isIdentityConnecting: authService.isConnecting,
    userId: () => authService.user()?.userId,
  });

  const services: Services = {
    auth: authService,
    locale: localeService,
    threads: threadsService,
    inputHistory: useInputHistoryService(threadsService),
    device: deviceService,
    environment,
    websocket: websocketService,
    experiments: experimentsService,
    appLayout: appLayoutService,
    feedback: feedbackService,
    knowledge: knowledgeService,
    limiting: limitingService,
    collections: collectionsService,
  };

  return {
    isExtension: () => deps.variant === "extension",
    isApp: () => deps.variant === "app",
    dict: dictionary,
    dependencies: {
      logger,
    },
    services,
    metadata: {
      isDefault: false,
      createdAt: performance.now(),
    },
    flags: {
      isDebug,
    },
  };
}
