import type { CommandKey, operation } from "@repo/client";
import type { ConnectedEvent, DisconnectedEvent, SendToServerEvent, ReceiveFromServerEvent } from "./wsEvents";

export const newSendToServerEvent = (commandKey: CommandKey, recipients: string[], data: string): SendToServerEvent => {
  return {
    type: "ws.send" as const,
    envelope: {
      commandKey,
      recipients,
      data,
    },
  };
};

export const newOnReceiveError = (message: string) => ({
  type: "ws.receiveError" as const,
  message,
});

export const newClearErrors = () => {
  return {
    type: "ws.clearErrors" as const,
  };
};

export const newReceiveFromServerEvent = (operation: operation.Response<string>): ReceiveFromServerEvent => {
  return {
    type: "ws.receive",
    operation,
  };
};

export const newConnectedEvent = (): ConnectedEvent => ({
  type: "ws.connected",
});

export const newDisconnectedEvent = (): DisconnectedEvent => ({
  type: "ws.disconnected",
});
